import * as React from 'react';
import {BaseFilter} from '@onsmart/ui-kit';
import * as isEqual from 'lodash.isequal';

import Chip from './Chip';
import ShowingsMediaTypeFilterPopper from './Popper/Popper';

import type {BaseFilterProps} from '@onsmart/ui-kit';
import type {SearchShowingsMediaTypes_searchShowingsMediaTypes_data} from 'components/pages/Showings/hooks/__generated__/SearchShowingsMediaTypes';
type Props = BaseFilterProps<string[]> & {
  mediaTypeList?: SearchShowingsMediaTypes_searchShowingsMediaTypes_data[];
};
const FILTER_ID = 'mediaName' as const;

class ShowingsMediaTypeFilter extends BaseFilter<Props, string[]> {
  static id: typeof FILTER_ID = FILTER_ID;

  static defaultProps: Partial<Props> = {
    ...BaseFilter.defaultProps,
    defaultValue: [],
    filterAddOption: {
      id: FILTER_ID,
      title: 'Media Type',
      description: 'Filter showings by media type.',
    },
  };

  render() {
    const {disabled, anchorEl} = this.context;
    const {value, initialValue, isOpen, resetTempValue, tempValue} = this;

    return (
      <div>
        {value.length > 0 && (
          <Chip
            handleClick={this.openPopper}
            onReset={this.onReset}
            isLoaded={!disabled}
            value={value}
            enableColor={this.hasInitialFilters}
            hasChanges={!isEqual(value, initialValue)}
          />
        )}
        {isOpen && (
          <ShowingsMediaTypeFilterPopper
            open={isOpen}
            anchorEl={anchorEl}
            value={tempValue || value}
            hasChanges={!!tempValue}
            onPopperClose={this.closePopper}
            onChange={this.onChange}
            resetTempValue={resetTempValue}
            mediaTypeList={this.props.mediaTypeList}
          />
        )}
      </div>
    );
  }
}

export default ShowingsMediaTypeFilter;
