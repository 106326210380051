import * as React from 'react';
import {Component} from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {FilterActionPopper, MultiSelectList} from '@onsmart/ui-kit';
import equal from 'fast-deep-equal';
import * as _isEmpty from 'lodash.isempty';

import {arrayToIndexed, generalToOption} from 'utils/array';

import {styles} from './styles';

import type {onCloseType, Option} from '@onsmart/ui-kit';
import type {Styles} from './styles';
import type {SearchShowingsMediaTypes_searchShowingsMediaTypes_data} from 'components/pages/Showings/hooks/__generated__/SearchShowingsMediaTypes';
interface ShowingsMediaTypeFilterPopperProps {
  value: string[];
  open: boolean;
  anchorEl: HTMLElement;
  hasChanges: boolean;
  resetTempValue: () => void;
  onChange: (markets: string[], ev?: React.SyntheticEvent) => void;
  onPopperClose: (temp?: string[]) => void;
  mediaTypeList: SearchShowingsMediaTypes_searchShowingsMediaTypes_data[];
}

interface ShowingsMediaTypeFilterPopperInternalProps
  extends ShowingsMediaTypeFilterPopperProps,
    Styles {}

interface ShowingsMediaTypeFilterPopperState {
  isMenuOpen: boolean;
  selected: string[];
}

class ShowingsMediaTypeFilterPopperComponent extends Component<
  ShowingsMediaTypeFilterPopperInternalProps,
  ShowingsMediaTypeFilterPopperState
> {
  filterComponent: any;

  constructor(props: ShowingsMediaTypeFilterPopperInternalProps) {
    super(props);
    this.state = {
      isMenuOpen: false,
      selected: props.value || [],
    };
  }

  handleSubmit = (ev: any) => {
    const {onChange} = this.props;
    const {selected} = this.state;

    onChange(selected, ev);
  };

  handleClose = (
    evt?: React.MouseEvent<HTMLElement> | React.ChangeEvent<{}>,
    type?: onCloseType,
  ) => {
    if (type === 'away' && this.state.isMenuOpen) {
      return;
    }

    const {selected} = this.state;
    this.props.onPopperClose(selected);
  };

  handleMenuOpen = () => {
    this.setState({isMenuOpen: true});
  };

  handleMenuClose = () => {
    this.setState({isMenuOpen: false});
  };

  handleMultiSelectedValueChange = (value: Option[]) => {
    const selected = value.map((m) => !_isEmpty(m) && m.value);
    const sortedSelected = (selected || []).sort();
    this.setState({selected: sortedSelected});
  };

  UNSAFE_componentWillReceiveProps(nextProps: ShowingsMediaTypeFilterPopperInternalProps) {
    if (!equal(nextProps.value, this.state.selected)) {
      this.setState({selected: nextProps.value});
    }
  }

  componentDidUpdate(
    prevProps: ShowingsMediaTypeFilterPopperInternalProps,
    prevState: ShowingsMediaTypeFilterPopperState,
  ) {
    if (
      this.state.selected !== prevState.selected &&
      this.state.selected.length > prevState.selected.length
    ) {
      if (this.filterComponent && this.filterComponent.scrollbars) {
        this.filterComponent.scrollbars.scrollToBottom(true);
      }
    }
  }

  render() {
    const {classes, anchorEl, open, hasChanges, resetTempValue} = this.props;
    const {selected} = this.state;
    const isSelectedEmpty = _isEmpty(selected);
    const options = this.getOptions();

    return (
      <FilterActionPopper
        innerRef={(ref) => (this.filterComponent = ref)}
        anchorEl={anchorEl}
        open={open}
        hasChanges={hasChanges}
        onClose={this.handleClose}
        headerTitle="Media type"
        onCancelLabel="Reset"
        onCancel={hasChanges && resetTempValue}
        disabled={isSelectedEmpty}
        onSubmit={this.handleSubmit}
      >
        <MultiSelectList
          label="Select media types"
          values={this.getFilterValues() || []}
          options={options || []}
          autoFocus
          onMenuOpen={this.handleMenuOpen}
          onMenuClose={this.handleMenuClose}
          onChange={this.handleMultiSelectedValueChange}
          classes={{rootWithValues: classes.selectWithValues}}
        />
      </FilterActionPopper>
    );
  }

  getFormattedMediaType = () => {
    const {mediaTypeList} = this.props;

    return mediaTypeList.reduce((acc, mediaType) => {
      return [...acc, generalToOption(mediaType.name)];
    }, []);
  };

  getOptions = () => {
    const formattedMediaType = this.getFormattedMediaType();
    const {selected} = this.state;

    const filterByValue = (mediaTypes: Option[]) => {
      const result = mediaTypes.filter((m) => {
        const has = selected.find((el) => el.includes(m.value)) ? true : false;
        return !has;
      });
      return result;
    };

    const filteredResults = filterByValue(formattedMediaType);

    return filteredResults;
  };

  getFilterValues = () => {
    const formattedMediaType = this.getFormattedMediaType();
    const {selected} = this.state;

    if (!formattedMediaType || formattedMediaType.length === 0) {
      return null;
    }
    const indexedMediaTypes = arrayToIndexed(formattedMediaType, 'value');
    return selected.reduce((acc, mediaTypeList) => {
      const mediaType = indexedMediaTypes[mediaTypeList];
      if (mediaType) {
        return [...acc, mediaType];
      }
      return acc;
    }, []);
  };
}

const ShowingsMediaTypeFilterPopper = withStyles(styles)(ShowingsMediaTypeFilterPopperComponent);

export default ShowingsMediaTypeFilterPopper;
